<script setup lang="ts">
import NewButton from '~/components/new/NewButton.vue';
import { type EntityType, entities } from '~/enitityHelper';
import { useUserStore } from '~/stores/UsersStore';
import VRadio from '~/components/common/form/VRadio.vue';
import VDivider from '~/components/new/VDivider.vue';
import { iconColors } from 'assets/js/utils';
import SkeletonLoader from '~/components/common/SkeletonLoader.vue';
import VCreate from '~/components/new/pages/index/VCreate.vue';

const route = useRoute();

const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });
const level = ref(props.entity);
const texts = entities[(props.entity as EntityType) || 'project'];
const activeIndex = Object.keys(entities).indexOf(props.entity);
onMounted(() => {
  const swiper = document.querySelector('.swiper')!.swiper;
  // swiper.activeIndex = activeIndex;
  swiper.slideTo(activeIndex);
  console.log(activeIndex);
});
</script>

<template>
  <div class="promo">
    <div class="promo-wrap">
      <div class="container">
        <h1 v-html="texts.title" />
      </div>
      <div class="container-swiper container">
        <Swiper
          :slides-per-view="2.3"
          :space-between="8"
          :breakpoints="{
            576: {
              slidesPerView: 5,
              spaceBetween: 12,
            },
          }"
          class="promo__tabs"
        >
          <SwiperSlide
            v-for="ent in Object.keys(entities)"
            :key="ent"
          >
            <a
              value="project"
              class="tabs__item"
              :class="{ active: level === ent }"
              :href="entities[ent].link"
            >
              <span class="title">{{ entities[ent].name }}&nbsp;</span>
              <span class="subtitle">{{ entities[ent].description }}&nbsp;</span>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
      <v-create
        :entity="props.entity"
        class="container"
      />
    </div>
  </div>
</template>

<style lang="scss">
.promo {
  padding: 152px 0 36px 0;
  @include media-breakpoint-down(md) {
    padding: 110px 0 36px 0;
  }

  .promo-wrap {
    margin: 0 auto;
    gap: 60px;

    .container-swiper {
      @include media-breakpoint-down(sm) {
        &.container {
          max-width: 100vw !important;
          padding: 0 !important;
        }
      }
    }

    .swiper {
      max-width: auto !important;

      @include media-breakpoint-down(md) {
        padding: 0 20px;
      }

      .swiper-wrapper {
        justify-content: start;
        margin: auto;
        align-items: stretch;
        margin-bottom: 24px;

        .swiper-slide {
          align-self: stretch;
          width: auto;
          margin-right: 12px;
          flex-grow: 1;
          max-width: 160px;
          height: auto;

          @include media-breakpoint-down(md) {
            flex-grow: 0;
            max-width: auto;
          }

          &:last-child {
            margin-right: 0 !important;
          }

          .tabs__item {
            margin-bottom: 0;
            height: 100%;
          }
        }
      }
    }

    h1 {
      color: $new-fr-contrast;
      text-align: start;
      margin-bottom: 0;
      font-size: 48px;
      text-wrap: balance;
      text-align: start;
      line-height: 52px;
      margin-bottom: 60px;
      max-width: 800px;
      font-weight: 600;

      @include media-breakpoint-down(md) {
        margin-bottom: 48px;
        font-size: 32px;
        line-height: 36px;
        padding: 0 20px 0 20px;
      }
    }

    .promo__tabs {
      .tabs__item {
        display: flex;
        flex-direction: column;

        font-size: 14px;
        padding: 8px 16px;
        background: white;
        border-radius: 12px;
        margin-bottom: 24px;

        &:hover {
          cursor: pointer;
        }

        .title {
          color: $new-fr-contrast;
          font-weight: 500;
          line-height: 20px;
        }

        .subtitle {
          color: $new-fr-gray;
          line-height: 18px;
        }

        &.active {
          border: none;
          background: #2b2433;

          .title {
            color: white;
            font-weight: 500;
            line-height: 20px;
          }

          .subtitle {
            color: rgba(255, 255, 255, 0.5);
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
