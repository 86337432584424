<script setup lang="ts">
import { ArrowRight, Sparkles } from 'lucide-vue-next';
import VTag from '~/components/new/VTag.vue';
import { generateProjectApi } from '~/api/projects';
import { entities, type EntityType } from '~/enitityHelper';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import { useAuthStore } from '~/stores/AuthStore';
import { useUserStore } from '~/stores/UsersStore';

const writeIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M4.7774 17.5L6.07202 16.1166H17.6861C17.8675 16.1166 18.0207 16.1867 18.1458 16.3271C18.2709 16.4607 18.3334 16.6211 18.3334 16.8083C18.3334 16.9954 18.2709 17.1558 18.1458 17.2895C18.0207 17.4298 17.8675 17.5 17.6861 17.5H4.7774ZM4.00813 16.6178L2.01929 17.4699C1.91297 17.5167 1.81915 17.4933 1.73785 17.3998C1.6628 17.3062 1.64716 17.2059 1.69094 17.099L2.54464 15.0439L12.3669 4.5678L13.8304 6.13169L4.00813 16.6178ZM14.5809 5.34974L13.0986 3.77582L13.9523 2.86355C14.1712 2.63632 14.3901 2.51602 14.609 2.50265C14.8342 2.4826 15.0437 2.57617 15.2376 2.78335L15.5003 3.06405C15.7004 3.27792 15.7942 3.50181 15.7817 3.73572C15.7692 3.96964 15.6535 4.2069 15.4346 4.4475L14.5809 5.34974Z" fill="black"/>
</svg>`;

const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });

const analyticsStore = useAnalyticsStore();
const authStore = useAuthStore();
const userStore = useUserStore();

const cookie = useCookie('query', { maxAge: 60 * 3, default: () => '' });
const input = ref(cookie.value);
watch(input, () => {
  cookie.value = input.value || '';
});

const level = ref(props.entity);
const texts = entities[(props.entity as EntityType) || 'project'];

const btnDisabled = ref(false);

watch(input, (prompt) => {
  analyticsStore.analyticsEnterPrompt(prompt);
});
async function generateProject(prompt: string, level: string) {
  if (prompt.length >= 2) {
    analyticsStore.analyticsSubmitPrompt(prompt);
    btnDisabled.value = true;
    if (userStore.user) {
      const p = await generateProjectApi(prompt, level, props.entity);
      if (window && window.ym) {
        window.ym(95948387, 'reachGoal', 'GENERATE');
      }
      await navigateTo(`/projects/${p.id}`);
    } else {
      authStore.showAuth = true;
    }
    btnDisabled.value = false;
  }
}
</script>

<template>
  <div class="form">
    <new-input
      v-model="input"
      class="input"
      schema="white"
      :placeholder="texts.placeholder"
      autofocus
      :disabled="btnDisabled"
      :icon-html-left="writeIcon"
      @keydown.enter="generateProject(input, level)"
    >
    </new-input>
    <new-button
      schema="primary"
      :disabled="btnDisabled"
      :loading="btnDisabled"
      class="create-button"
      @click="generateProject(input, level)"
    >
      Создать
      <template #iconLeft>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M13.4408 2.5L12.6589 4.83853L10.3134 5.61804V6.54713L12.6589 7.32664L13.4408 9.66517H14.3727L15.1545 7.32664L17.5 6.54713V5.61804L15.1545 4.83853L14.3727 2.5H13.4408Z"
            fill="white"
          />
          <path
            d="M5.95057 10.8371L7.10076 7.39677H8.03263L9.18282 10.8371L12.6334 11.9838V12.9129L9.18282 14.0597L8.03263 17.5H7.10076L5.95057 14.0597L2.5 12.9129V11.9838L5.95057 10.8371Z"
            fill="white"
          />
        </svg>
      </template>
    </new-button>
  </div>
</template>

<style scoped lang="scss">
.form {
  display: grid;
  grid-template-columns: 10fr 2fr;

  flex-direction: row;
  justify-content: center;
  gap: 12px;
  margin: auto;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    gap: 12px;
    width: 100%;
    padding: 0 20px;
  }

  .input {
    flex: 1;
    > div > div > input {
      background: white !important;
    }
  }
}
</style>
