<script setup lang="ts">
import ProjectCard from '~/components/new/cards/ProjectCard.vue';
import VTag from '~/components/new/VTag.vue';
import { getProjects } from '~/api/projects';
import { entities, type EntityType } from '~/enitityHelper';
import NewButton from '~/components/new/NewButton.vue';
const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });
const { data: projects } = await useAsyncData('lastProjects', () => getProjects(props.entity), {
  deep: false,
});

const projectsPerPage = 8;
const page = ref(1);
const shownProjects = computed(() => projects.value.slice(0, projectsPerPage * page.value));
const texts = entities[(props.entity as EntityType) || 'project'];
</script>

<template>
  <div class="last-projects">
    <div class="container">
      <h2>{{ texts.lastProjectsTitle }}</h2>
      <div class="last-projects__projects">
        <ProjectCard
          v-for="(item, idx) in shownProjects"
          :key="idx"
          :schema="item.payed_status ? 'pro-white' : 'default'"
          :project-card="item"
          :show-header="false"
        />
      </div>
      <new-button
        schema="outline"
        class="more-button"
        :class="{ hidden: shownProjects.length >= projects.length }"
        @click="page++"
      >
        Показать еще
      </new-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.last-projects {
  padding-top: 48px;
  padding-bottom: 80px;
  @include media-breakpoint-down(sm) {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    margin-bottom: 48px;
    color: $new-fr-contrast;
    text-align: start;
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 28px;
    }
  }

  .last-projects__projects {
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @include media-breakpoint-down(lg) {
      margin-bottom: 12px;
      gap: 12px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .more-button {
    width: 100%;

    &:not(:hover) {
      background: white;
    }

    &.hidden {
      display: none;
    }
  }
}
</style>
